<template>
  <router-view />
</template>

<style>
#app {
  min-height: 100% !important;
  height: 100% !important;
}
</style>
